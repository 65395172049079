@mixin root-font-sizes {
	--size-1: 82px;
	--size-2: 50px;
	--size-3: 40px;
	--size-4: 34px;
	--size-5: 28px;
	--size-6: 24px;
	--size-7: 22px;
	--size-8: 20px;
	--size-9: 18px;
	--size-10: 16px;
	--size-11: 14px;
	--size-12: 12px;
	--size-13: 10px;
  --size-14: 56px;
}

@mixin root-font-weights {
	--font-light: 300;
	--font-normal: 400;
	--font-medium: 500;
	--font-demi-bold: 600;
	--font-bold: 700;
	--font-extra-bold: 800;
	--font-black: 900;
}

@mixin root-fonts {
  --font-lexend: Lexend;
  --font-sans-serif: sans-serif;
}
