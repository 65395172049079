@use "sass:list";
@import 'styles/makers/functions';

@mixin make-font-style {
	.font-italic {
		font-style: italic;
	}

	.text-underline {
		text-decoration: underline;
	}
}

@mixin make-font-weight {
	.font-light {
		font-weight: 300;
	}

	.font-normal {
		font-weight: 400;
	}

	.font-medium {
		font-weight: 500 !important;
	}

	.font-demi-bold {
		font-weight: 600;
	}

	.font-bold {
		font-weight: 700 !important;
	}

	.font-extra-bold {
		font-weight: 800;
	}

	.font-black {
		font-weight: 900;
	}
}

@mixin make-font-size($size, $index) {
	.font-size-#{$index} {
		font-size: $size;
	}
}

@mixin make-responsive-font-size($size, $index, $breakpoint) {
	.font-size-#{$index}-#{$breakpoint} {
		font-size: $size;
	}
}

@mixin make-font-sizes($size-list: $sizes) {
	@for $i from 1 through length($size-list) {
		$size: nth($size-list, $i);
		@include make-font-size($size, $i);
	}
}

@mixin make-responsive-font-sizes($size-list: $sizes, $breakpoint) {
	@for $i from 1 through length($size-list) {
		$size: nth($size-list, $i);
		@include make-responsive-font-size($size, $i, $breakpoint);
	}
}
