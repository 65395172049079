@import 'styles/makers/make-fonts';
@import 'styles/makers/make-colors';
@import 'styles/makers/make-shadows';

@import 'styles/variables/fonts';
@import 'styles/variables/colors';
@import 'styles/variables/lists';
@import '../variables/globals.scss';

@mixin make-base() {
	:root {
		@include root-font-sizes;
		@include root-font-weights;
		@include root-colors;
	}

	@include make-shadows;

	/*
  usage: .font-size-[index]
  */
	@include make-font-sizes($font-size-list);
	@include make-font-weight;
	@include make-font-style;

	/*
  usage:  .front-{main|sub|fade}-1 (text color)
          .back-{main|sub|fade}-1 (background color)
  */
	@include make-back-front-classes($main-color-list, 'main');
	@include make-back-front-classes($sub-color-list, 'sub');
  @include make-back-front-classes($fade-color-list, 'fade');

	html,
	body {
		font-family: 'Montserrat', sans-serif !important;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	.spacer {
		flex: 1 1 auto;
	}

	.mat-form-field {
		width: 100%;
	}

	.container-row {
		display: grid;
		padding: 0 0 30px 0;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	@media only screen and (min-width: $screen-md) {
		@include make-responsive-font-sizes($font-size-list, 'md');
	}

	@media only screen and (min-width: $screen-sm) {
		@include make-responsive-font-sizes($font-size-list, 'sm');
	}
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
