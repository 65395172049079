@use "sass:list";

@mixin make-front-class($index, $color, $color-type) {
	.front-#{$color-type}-#{$index} {
		color: #{$color} !important;
	}
}

@mixin make-back-class($index, $color, $color-type) {
	.back-#{$color-type}-#{$index} {
		background-color: #{$color} !important;
	}
}

@mixin make-back-front-class($index, $color, $color-type) {
	@include make-front-class($index, $color, $color-type);
	@include make-back-class($index, $color, $color-type);
}

@mixin make-back-front-classes($color-list, $color-type) {
	@for $i from 1 through length($color-list) {
		$color: nth($color-list, $i);
		@include make-back-front-class($i, $color, $color-type);
	}
}
