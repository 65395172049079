// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
@use '~angular-notifier/styles/core.scss';
@use '~angular-notifier/styles/themes/theme-material.scss';
@use '~angular-notifier/styles/types/type-success.scss';
@use '~angular-notifier/styles/types/type-error.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$flink-primary: mat.define-palette(mat.$indigo-palette);
$flink-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$flink-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$flink-theme: mat.define-light-theme(
	(
		color: (
			primary: $flink-primary,
			accent: $flink-accent,
			warn: $flink-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($flink-theme);

/* You can add global styles to this file, and also import other style files */

@import 'styles/makers/make-base';

@include make-base();

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: 'Roboto', 'Helvetica Neue', sans-serif, Lexend;
}
* {
	font-family: 'Roboto', 'Helvetica Neue', sans-serif, Lexend;
}

.rgba-black-light,
.rgba-black-light::after,
.rgba-black-strong,
.rgba-black-strong::after,
.rgba-black-slight,
.rgba-black-slight::after {
	background-color: transparent !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
	padding: 0px !important;
}

.mat-horizontal-content-container {
	overflow: hidden;
	padding: 0 !important;
}

.carousel-indicators li {
	background-color: #282828 !important;
}

.carousel .carousel-indicators li {
	width: 55px !important;
	height: 7px !important;
	border-radius: 0px !important;
}

.edit-icon {
	color: var(--main-1);
	cursor: pointer;
	float: right;
}
.mat-form-field-infix {
	border-top: 0px solid transparent !important;
}
.mat-step-header {
	margin-left: auto !important;
	margin-right: auto !important;
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
	background-color: transparent !important;
}
.carousel {
	box-shadow: 0 3px 6px #00000016;
}

.table-container {
	overflow: auto;
}

.required:after {
	color: var(--main-5);
	content: '*';
	position: relative;
	margin-left: 5px;
	top: 0px;
}
.w-80 {
	width: 80%;
}
.mat-tab-label {
	width: 30% !important;
}
.mat-card-content {
	width: 100%;
	margin: 0 auto;
}
.mat-tab-header {
	border-bottom: none;
}
// Checkbox overrides
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: #097ff5 !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background-color: #097ff5 !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background-color: #097ff5 !important;
}
// Radio button group overrides
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #097ff5 !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #097ff5 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: #097ff5 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #097ff5 !important;
}
.mat-radio-button.mat-accent .mat-ripple-element {
  background-color: #097ff5 !important;
}
// remove number input arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.mat-card:not([class*='mat-elevation-z']) {
	box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.04), 0px 18px 28px 2px rgba(0, 0, 0, 0.04),
		0px 7px 34px 6px rgba(0, 0, 0, 0.04);
}

.tooltip-in-label {
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 24px;
	height: 24px;
}


#job-referral-dialog {
  padding: 0 !important;
  overflow-x: hidden;
}

// Drag and drop
.list {
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.list-item-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.list-item-box:last-child {
  border: none;
}

.list.cdk-drop-list-dragging .list-item-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.multiline-tooltip  {
  white-space: pre-line;
}

.button-disabled {
  opacity: 0.65;
}



@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.blue-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #097ff5;
  animation: spinner .8s linear infinite;
}


.loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #097ff5;
  animation: spinner .8s linear infinite;
}
