// Main colours
$main-1: #314df0;
$main-2: #54c280;
$main-3: #0cd9c0;
$main-4: #0d3691;
$main-5: #ff4548;
$main-6: #3b65b0;
$main-7: #258dcc;
$main-8: #dc3d35;
$main-9: #ff5416;
$main-10: #ffb200;
$main-11: #505969;

// Hover colours
$main-hov-1: #283fc0;
$main-hov-2: #387e54;
$main-hov-3: #088d7d;
$main-hov-4: #071c49;
$main-hov-5: #96292b;
$main-hov-6: #223c69;
$main-hov-7: #144d70;
$main-hov-8: #832621;
$main-hov-9: #812b0c;
$main-hov-10: #ce9206;

// faded colours
$fade-1: #9eacfd5b;
$fade-2: #54c2803f;
$fade-3: #e8fff1;
$fade-4: #505969;
$fade-5: #ff454818;
$fade-10: #ffe3a13f;

// Sub colours
$sub-1: #ffffff;
$sub-2: #f8f8f8;
$sub-3: #231f20;
$sub-4: #454545;
$sub-5: #707070;
$sub-6: #00000012;
$sub-7: #00000026;
$sub-8: #c0c0c0;
$sub-9: #d5d5d5;
$sub-10: #dddede;
$sub-11: #efefef;
$sub-12: #F4F7FA;

// Error
$err-1: #ff0000;

@mixin root-colors {
	// Main colours
	--main-1: #{$main-1};
	--main-2: #{$main-2};
	--main-3: #{$main-3};
	--main-4: #{$main-4};
	--main-5: #{$main-5};
	--main-6: #{$main-6};
	--main-7: #{$main-7};
	--main-8: #{$main-8};
	--main-9: #{$main-9};
	--main-10: #{$main-10};
	--main-11: #{$main-11};

	// Faded colours
	--fade-1: #{$fade-1};
	--fade-2: #{$fade-2};
	--fade-3: #{$fade-3};
  --fade-4: #{$fade-4};
	--fade-5: #{$fade-5};
	--fade-10: #{$fade-10};

	// Hover colours
	--main-hov-1: #{$main-hov-1};
	--main-hov-2: #{$main-hov-2};
	--main-hov-3: #{$main-hov-3};
	--main-hov-4: #{$main-hov-4};
	--main-hov-5: #{$main-hov-5};
	--main-hov-6: #{$main-hov-6};
	--main-hov-7: #{$main-hov-7};
	--main-hov-8: #{$main-hov-8};
	--main-hov-9: #{$main-hov-9};
	--main-hov-10: #{$main-hov-10};

	// Sub colours
	--sub-1: #{$sub-1};
	--sub-2: #{$sub-2};
	--sub-3: #{$sub-3};
	--sub-4: #{$sub-4};
	--sub-5: #{$sub-5};
	--sub-6: #{$sub-6};
	--sub-7: #{$sub-7};
	--sub-8: #{$sub-8};
	--sub-9: #{$sub-9};
	--sub-10: #{$sub-10};
	--sub-11: #{$sub-11};
  --sub-12: #{$sub-12};

	// Error colour
	--err-1: #{$err-1};
}
